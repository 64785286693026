/***Homepages agents & inspecteurs***/
$institutions-map: (
  "bcss":"bcss",
  "caami":"caami",
  "capac":"capac_fr",
  "cgslb":"cgslb_fr",
  "csc":"csc_fr",
  "fedris":"fedris",
  "fgtb":"fgtb_fr",
  "fsma":"fsma",
  "inami":"inami",
  "inasti":"inasti",
  "onem":"onem",
  "noss":"noss",
  "nosslpa":"nosslpa",
  "onva":"onva",
  "sfp":"sfp",
  "sigedis":"sigedis",
  "spfemploi":"spf_emploi",
  "spfsp":"spf_sante",
  "spfss":"spf_securite_sociale"
);

$institutions-nl: ("capac","cgslb","csc","fgtb");
$institutions-de: ("capac","cgslb");

$pathlogo: "/static/images/logos/filtre/" !default;

#content.home-agents,
#content.home-inspecteurs {
  #filters ul {
    @include media-breakpoint-up(md){
      li {
        font-size: em(12);
        text-align: center;
        vertical-align: middle;
        line-height: 40px;
        background-color: $gray-100;
        margin-bottom: 0.5rem;
        a {
          display: block;
          height: 40px;
          width: 40px;
          color: $body-color;
          text-decoration: none;
        }
        &.current {
          a[href="#all"] {
            background-color: $primary;
            color: $white;
            text-decoration: none;
          }
        }
      }

      @each $institution, $logo in $institutions-map {
        li a[href="##{$institution}"] {
          background: url("#{$pathlogo}#{$logo}.png") no-repeat 0 0;
        }
        &.activeFilter li {
          a[href="##{$institution}"] {
            background-position: 0 (-42px);
            &:hover {
              background-position: 0 0;
            }
          }
          &.current a[href="##{$institution}"] {
            background-position: 0 0;
          }
        }
        // find if logo is translatable in NL
        @each $trans in $institutions-nl {
          @if $institution == $trans {
            html[lang="nl"] & {
              li a[href="##{$institution}"] {
                background-image: url("#{$pathlogo}#{$institution}_nl.png");
              }
            }
          }
        }
        // same for DE
        @each $trans in $institutions-de {
          @if $institution == $trans {
            html[lang="de"] & {
              li a[href="##{$institution}"] {
                background-image: url("#{$pathlogo}#{$institution}_de.png");
              }
            }
          }
        }
      }
    }
  }
}