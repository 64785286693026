#content {
  .nav-tabs {
    margin: rem(50) 0;
    .nav-item {
      margin-right: rem(50);
    }
    .nav-link {
      padding: rem(10) 0;
      border: 0;
      border-bottom: 6px solid transparent;
      text-transform: uppercase;
      color: $body-color;
      &.active {
        border-bottom-color: $primary;
      }
    }
  }
}