.choice {
  .card {
    height: auto;
    @include media-breakpoint-up(md) {
      padding: 1rem 1rem 1rem .75rem;
      margin-bottom: rem(20);
      .card-header {
        min-height: 0;
      }
    }
    .card-title {
      font-family: $font-family-primary;
      font-weight: 500;
      font-size: rem(16);
      margin-bottom: 0;
      @include media-breakpoint-up(lg) {
        font-size: rem(18);
      }
    }
  }
}