.home-employer {
  .content-header-title {
    h2 {
      font-size: rem(24);
      @include media-breakpoint-up(md) {
        font-size: rem(28);
      }
    }
  }
  #searchfield {
    margin-top:0;
    margin-bottom:0;
    .input-group{
      max-width:25rem;
    }
  }
  #services {
    ul {
      column-gap: 8.33333333%;
    }
    li {
      padding-bottom: rem(24);
    }
    a {
      text-decoration: none;
      h3 {
        text-decoration: underline;
      }
      &:hover {
        h3 {
          text-decoration: none;
        }
      }
    }
  }
  .quick-acces {
    &:hover {
      .highlighted-title  {
        background: $primary;
      }
    }
  }
  #news {
    .news-highlight{
      h3 {
      @include media-breakpoint-up(md) {
        font-size: rem(25);
      }
    }
   }
  }
}