@include media-breakpoint-up(md) {
  .home-civilservant {
    .col {
      padding-bottom: $grid-gutter-width;
    }
  }
}

@include media-breakpoint-down(sm) {
  .home-agents, .home-inspecteurs {
    #services {
      .card .card-header {
        border-top: 1px solid rgba(0, 0, 0, 0.125);
        border-bottom: 0;
        font-weight: 500;

        &:after {
          content: none;
        }
      }
    }
  }
}