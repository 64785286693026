table {
  caption {
    caption-side: top;
  }
}
.table {
  border: 1px solid $border-color;
  border-right: 0;
  margin-bottom: 0;
  thead tr {
    @include background-primary;
    th {
      color: $white;
      border: 0;
      vertical-align: top;
      padding: 1.1rem 0.75rem;
    }
  }
}
.table-responsive > .table-bordered {
  border: 1px solid $border-color;
}

.table-responsive {
  &:not(:first-child) {
    margin-top: rem(40);
  }
  &:not(:last-child) {
    margin-bottom: rem(40);
  }
}