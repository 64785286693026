$circle-size: 40px;
$nsteps: 3;
.secured-access {
  .steps {
    position: relative;
    list-style: none;
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    margin-top: 0;
    margin-bottom: rem(40);
    @include media-breakpoint-up(lg) {
      margin-top: rem(50);
      margin-bottom: rem(64);
      flex-direction: row;
      margin-left: -.75rem;
      margin-right: -.75rem;
    }
    li {
      position: relative;
      display: flex;
      padding: 1rem 0;
      margin: 0 !important;
      @include media-breakpoint-up(lg) {
        flex: 0 0 calc(100% / #{$nsteps});
        max-width: calc(100% / #{$nsteps});
        display: block;
        padding: 0 .75rem;
      }
      .number {
        background-color: $gray-200;
        width: $circle-size;
        height: $circle-size;
        flex: 0 0 $circle-size;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 3;
        position: relative;
        @include media-breakpoint-up(lg) {
          margin: 0 auto;
        }
      }
      @include media-breakpoint-up(lg) {
        &:first-child:after {
          top: calc(#{$circle-size} / 2);
          left: 50%;
        }
        &:last-child:after {
          right: 50%;
          bottom: auto;
        }
      }
      .content {
        width: 100%;
        height: calc(100% - #{$circle-size});
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 1rem;
        @include media-breakpoint-up(lg) {
          padding-top: rem(24);
          align-items: center;
          text-align: center;
          margin: 0;
        }
        p {
          margin-bottom: 0;
        }
      }
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 1px;
      // border-left: 1px dotted $gray-600;
      @include border-dotted($gray-600, left);
      top: 1rem;
      bottom: 20%;
      left: calc(#{$circle-size} / 2);
      @include media-breakpoint-up(lg) {
        width: auto;
        height: 1px;
        @include border-dotted($gray-600, bottom);
        top: calc(#{$circle-size} / 2);
        left: calc(100% / #{$nsteps} / 2);
        right: calc(100% / #{$nsteps} / 2);
        bottom: auto;
      }
    }
  }
}