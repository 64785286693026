#profiles-cards {
  margin-top: rem(30);
  margin-bottom: rem(30);
  font-size: rem(14);
  border-bottom: 0;
  @include media-breakpoint-up(lg) {
    display: flex;
    margin-right: -$card-deck-margin;
    margin-left: -$card-deck-margin;
    margin-top: rem(40);
    margin-bottom: rem(50);

    .profile-card {
      flex: 0 0 calc(25% - #{$card-deck-margin} * 2);
      max-width: calc(25% - #{$card-deck-margin} * 2);
      margin-left: $card-deck-margin;
      margin-right: $card-deck-margin;
    }
  }
  .profile-card {
    overflow: hidden;
    .card-header {
      background-color: $white;
      border-bottom: 0;
      background-position: bottom;
      background-image: linear-gradient(to right,#737779 30%,rgba(255,255,255,0) 10%);
      background-size: 4px 1px;
      background-repeat: repeat-x;
      @include media-breakpoint-up(lg) {
        padding: .5rem;
      }
      .h4 {
        margin: 0 !important;
        font-size: rem(16);
        @include media-breakpoint-up(lg) {
          min-height: 48px;
        }
      }
      &[data-toggle="collapse"] {
        @include collapse-icon(16px);
        padding-left: 2rem;
        &:after {
          margin-left: 6px;
          margin-right: 15px;
          margin-top: 6px;
        }
      }
    }
    .card-body {
      padding: 0;
      // flex: 0 0 auto;
      .option {
        display: flex;
        align-items: flex-start;
        padding: 1rem !important;
        @include media-breakpoint-up(lg) {
          padding: 1rem .5rem !important;
        }
        img,
        .fa, .fas, .far {
          margin-right: rem(8);
        }
        .fa, .fas, .far {
          font-size: rem(18);
          -webkit-text-stroke: .5px $white;
          text-stroke: .5px $white;
        }
        &.option4 {
          margin-bottom: 1rem;
        }
        // @include media-breakpoint-up(lg) {
        //   &.option2 {
        //     min-height: 30%;
        //   }
        // }
      }
    }
    @include media-breakpoint-up(lg) {
      .line {
        position: relative;
        &:after {
          content: '';
          border-bottom: 1px solid $body-color;
          position: absolute;
          top: 50%;
          left: 0;
          width: 24px;
        }
      }
    }
  }
}