.firstVisitHome {
  #content {
    padding-top: 0;
  }
}
.firstVisitInner {
  ul.steps {
    li {
      display: flex;
      strong {
        min-width: em(60,14);
      }
    }
  }
}