#searchfield {
  margin-top: rem(34);
  margin-bottom: rem(30);
  .btn:focus {
    box-shadow: 0 0 0 0.2rem rgb($dark, .5);
  }
}
#filters {
  .label,
  .custom-control {
    margin-right: rem(18);
    @include media-breakpoint-up(lg) {
      margin-right: rem(28);
    }
  }
  .label {
    font-size: 1rem;
  }
  .custom-control-input:focus~.custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgb($dark, .5);
  }
}
#services, #themes {
  .col-12 {
    @include media-breakpoint-up(md) {
      margin-bottom: $grid-gutter-width;
    }
  }
  .card {
    @extend .card-link;
    @include media-breakpoint-up(md) {
      .card-header {
        min-height: 110px;
      }
    }
  }
}