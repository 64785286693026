// .alert {
//     margin-bottom: 0;
    
//     &.alert-danger {
//         background-color: $red;
//         color: $white;

//         .close-icon span {
//             background-color: $white;
//         }
//     }

//     .close-icon {
//         position: absolute;
//         right: 1.5rem;
//         top: .5rem;
//         z-index: 1;
//     }
// }

.alert {
    border-radius: 0 0 $border-radius 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    font-size: rem(14);
    @include media-breakpoint-up(md) {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
    }
    > .badge {
        border-radius: 0 0 $border-radius-sm 0;
        padding: 0.5rem;
        font-family: $font-family-sans-serif;
        font-size: em(12,14);
        font-weight: normal;        
        text-transform: uppercase;
        color: $white;
        margin-bottom: 1em;
        @include media-breakpoint-up(md) {
            margin-bottom: 0;
        }
    }
    .alert-content {
        @include media-breakpoint-up(md) {
            width: calc(100% - 70px);
            padding-top: 0.25rem;
        }
        .alert-date {
            display: block;
            font-weight: bold;
            margin-bottom: .5rem;
        }
        a {
            color: $body-color;
        }
        h2 {
            font-size: rem(14) !important;
            margin: 0 !important;
        }
        .alert-message {
            & + .alert-message {
               margin-top: rem(20);
               padding-top: rem(20);
               border-top: 1px solid $border-color;
            }
        }
        hr {
            margin: rem(20) 0;
        }
        .collapse-content {
            padding-top: .5rem;
        }
    }
    &.alert-info {
        color: $body-color;
        background-color: rgba($green,.07);
        > .badge-info {
            background-color: $green;
        }
    }
    &.alert-danger {
        color: $body-color;
        background-color: $bg-light-yellow;
    }
}