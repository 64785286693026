/*** ISSA ***/
#content.issa .rowitem {
  border: 1px solid $gray-300;
  border-radius: 0;
  margin-bottom: 0.643em;
  min-height: 36px;

  a.firstLv {
    cursor: pointer;
    display: block;
    margin-bottom: 0;
  }

  h2, h3, h4, h5 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .well {
    padding: 15px;
    .well {
      border: 1px solid $border-color;
      background-color: $white;
      border-radius: 0;
      box-shadow: none;
      margin: 1em 1em 1em 3.6em;
      padding: 5px;

      .well {
        padding: 0;
        .well {
          border: 0 none;
        }
      }
    }
  }
  h5 {
    background-color: #f4f4f4;
  }
}