#news {
  a.accordion-title {
    color: $body-color;
    padding: 0 2rem;
    &:hover,
    &:focus {
      text-decoration: none !important;
      h2 {
        text-decoration: underline;
      }
    }
  }
  .date {
    font-size: rem(12);
    line-height: 3;
    color: $primary;
  }
  .accordion-content {
    width:75%;
    @include media-breakpoint-down(md) {
      width:100%;;
    }
    padding: .5rem 0 1rem !important;
    img {
      border-top: 2px solid $primary;
      max-width: 444px;
    }
  }
}