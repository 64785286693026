.autocomplete-suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  margin: 2px 0 0;
  list-style: none;
  font-size: 14px;
  background-color: $white;
  border: 1px solid $gray-400;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  background-clip: padding-box;
  overflow: auto;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.176);

  a {
    margin: 10px;
    text-decoration: none;
    display: block;
    color: $body-color;
    line-height: 1.2;
  }

  strong {
    color: $blue;
  }
}

.autocomplete-selected {
  background: none repeat scroll 0 0 $gray-100;
}