.btn {
    &.btn-primary {
        transition: 0.2s ease;
        @include background-primary;

        &:hover {
            background-image: none;
            background-color: $primary;
            border-color: $primary;
        }
    }

    &.btn-outline-white {
        color: $white;
        border-color: $white;

        &:hover {
            color: $primary;
            background-color: $white;
        }
    }

    &.btn-outline-primary {
        color: $primary !important;
        border-color: $primary;
        background-color: $white;

        &:hover {
            color: $white  !important;
            background-color: $primary;
        }
    }

    &.btn-append {
        border-color: $input-border-color !important;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;

        &:hover, &:focus, &:active {
            background-color: inherit !important;
        }
    }

    &.btn-select {
        color: $body-color;
        background-color: $white;
        border-color: $gray-500;
        text-align: left;
        padding-right: 2rem !important;

        &:after {
            color: $primary;
            right: .75rem;
        }

        &:hover,
        &:focus,
        &.focus {
            color: $primary;
            background-color: $white;
        }
    }

    &.disabled, &:disabled {
        background: $gray-600 !important;
    }
}

.btn-round {
    width: 42px;
    height: 42px;
    line-height: 42px;
    text-align: center;
    border-radius: 50%;
    padding: 0;

    &.btn-lg {
        width: 54px;
        height: 54px;
        line-height: 54px;
    }
}