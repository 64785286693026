#content.help-node {
  article {
    .row:not(:last-of-type) {
      .col-md-6 {
        border-bottom: 1px solid $border-color;
      }
    }
  }
  ul {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }
}