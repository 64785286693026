ul, ol {
    &.list-simple {
        padding-left: 1.1em;
    
        li + li {
            margin-top: 0.5rem;
        }
    }
}

#contentWrapper {
    ul, ol {
        &:not(.nav):not(.breadcrumb) {
            li + li {
                margin-top: .5rem;
            }
        }
        li {
            p {
                margin-bottom: .25rem;
            }
        }
        ul {
            margin-top: .5rem;
            margin-bottom: 1rem;
            list-style-type: disc;
        }
    }
    ul:not(.list-unstyled):not(.list-inline):not(.nav):not(.breadcrumb) {
        padding-left: 1.1em;
    }
    ol:not(.list-unstyled):not(.list-inline):not(.nav):not(.breadcrumb) {
        padding-left: 1.5rem;
    }
    .list-unstyled .list-unstyled {
        padding-left: 1.1em;
        list-style-type: none;
    }
}