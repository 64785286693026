#content {
  [data-toggle="collapse"]:not(.dropdown-toggle):not(.filter-toggle),
  [data-toggle="collapse-auto"]:not(.dropdown-toggle):not(.filter-toggle) {
    color: $body-color;
    @include collapse-icon();
    padding-left: 1.5rem;
    + div {
      padding-left: 1.5rem;
      > :first-child {
        padding-top: 0.25rem;
      }
      > :last-child {
        padding-bottom: .75rem;
        margin-bottom: 0;
      }
    }
    &.collapse-title,
    &.accordion-title {
      @include collapse-icon(1em, 0.6em);
    }
    &.accordion-title[aria-expanded]:after {
      top: 2rem;
    }
  }

  .alert {
    [data-toggle="collapse"],
    [data-toggle="collapse-auto"] {
      padding-left: 1rem;
      + div {
        padding-left: 1rem;
      }
    }
  }

  .faq, .help-node & {
    [data-toggle="collapse"],
    [data-toggle="collapse-auto"] {
      text-decoration: underline;
    }
  }

  #filter {
    [data-toggle="collapse"] {
      color: $primary;
    }
  }
}