#content.techlib {
  table {
    i {
      &.fa, &.far, &.fas {
        font-size: 90%;
      }
    }
    th {
      a {
        color: $white;
      }
    }
    td {
      a {
        color: $body-color;
      }
    }
    ul:not(:first-child) {
      margin-top: .5rem;
    }
  }
  .dynamicalBox .dynamicalBox {
    margin-top: 2.5rem;
  }
}