[data-toggle="dropdown"] {
    position: relative;
    padding-right: 1.5rem !important;
    
    &:after {
        @include faw-icon($fa-var-chevron-down, 10);
        position: absolute;
        top: calc(50% + 1px);
        right: .5rem;
        transform: translateY(-50%);
        border: 0;
    }

    // &:after {
    //     content: none;
    // }

    &[aria-expanded="true"] {
        &:after {
            @include faw-icon($fa-var-chevron-up, 10);
        }
    }
}