#contentWrapper.calendar {
  #collapseFilters {
    border: 1px solid $border-color;
    @include media-breakpoint-up(md) {
      padding-left: 0;
      border: 0;
    }
    .custom-control-label {
      font-size: $font-size-small;
      line-height: 1.75;
    }
  }
  .form-inline {
    #view-filters {
      display: flex;
      .custom-control {
        margin-right: 1rem;
      }
    }
  }
  #showCalendar {
    position: relative;
    .events-nav {
      position: relative;
      @include media-breakpoint-up(lg) {
        max-width: 85%;
        margin: 0 auto;
      }
      @include media-breakpoint-up(xl) {
        max-width: 75%;
      }
      button {
        position: absolute;
        padding: 5px 20px;
        top: 0;
        i {
          color: $body-color;
        }
        &.prev {
          left: 20px;
        }
        &.next {
          right: 20px;
        }
        &.disabled {
          opacity: .2;
        }
        &:hover,&:focus {
          text-decoration: none;
        }
      }
    }
    .events-list {
      margin: 2rem calc($grid-gutter-width / -2);
      padding: 0;
      li {
        overflow: hidden;
        display: block;
        margin-top: 2rem;
        padding-top: 2rem;
        &:before {
          content: none;
        }
        & + li {
          border-top: 1px solid $border-color;
        }
        .showDate {
          color: $green;
          margin-bottom: 1rem;
        }
        .event + .event {
          margin-top: 1.5rem
        }
        small {
          display: block;
          color: $body-color;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  #contentWrapper.calendar {
    #showCalendar {
      .events-nav {
        button {
          &.prev {
            left: 50px;
          }
          &.next {
            right: 50px;
          }
        }
      }

      .events-list {
        li {
          display: flex;

          .showDate {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}