@use 'sass:math' as math;

$jumbotron-divider-thickness: 4px;

.jumbotron {
    .jumbotron-title,
    .jumbotron-lead {
        > *:last-child {
            margin-bottom: 0;
        }
    }
    h1 {
        line-height: 1.25;
    }

    .jumbotron-lead {
        margin-top: rem(20);
    }

    @include media-breakpoint-up(xl) {
        .jumbotron-lead {
            position: relative;
            margin-top: 0;
            // margin-left: rem(45);
            padding-left: rem(45);

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: -(math.div($jumbotron-divider-thickness, 2));
                bottom: 0;
                width: $jumbotron-divider-thickness;
                @include background-primary(to bottom);
            }
        }
    }
}

.jumbotron2 {
    @include background-primary;
    color: $white;
    position: relative;
    border-bottom-right-radius: $border-radius;

    > * {
        z-index: 1;
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        @if $img-ext == "png" {
            background-image: url('#{$static-path}/background/logo_white_big.png');
        } @else {
            background-image: url('#{$static-path}/background/logo_white.svg');
        }
        background-repeat: no-repeat;
        background-position: 100% 50%;
        background-size: 433px 435px;
        opacity: 0.1;
        z-index: 0;
    }

    &:before {
        content: '';
        background-color: $primary;
        position: absolute;
        top: 0;
        left: -150%;
        bottom: 0;
        width: 150%;
    }
}