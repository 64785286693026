.card {
    .card-header {
        margin-top: 0 !important;
    }
    .card-text {
        > *:last-child {
            margin-bottom: 0;
        }
    }

    &.card-news, &.card-panel {
        .card-img-top {
            border-bottom-right-radius: $border-radius;
            position: relative;
            overflow: hidden;

            img {
                width: 100%;
            }

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                height: 2px;
                background-color: $primary;
            }
        }
    }
    &.card-panel {
        border: 0;
        border-radius: 0;
        .card-body {
            padding-left: 0;
            padding-right: 0;
        }
    }

    // hp citizen - with arrow
    &.card-vignette {
        border: 0;
        border-radius: 0;
        position: relative;
        padding: 1.25rem 0;

        &:before {
            content: '';
            position: absolute;
            top: auto;
            left: 0;
            right: 0;
            bottom: 0;
            height: 2px;
            background-color: $primary;
        }

        * {
            color: $body-color;
        }

        @include media-breakpoint-up(md) {
            background-color: $light;
            border-bottom-right-radius: $border-radius;
            padding: 1.25rem 0.75rem;

            &:before {
                top: 0;
                bottom: auto;
            }

            &:hover {
                @include background-primary;
    
                &:before {
                    content: none;
                }
    
                * {
                    color: $white;
                }
            }
        }
    }

    &.card-block,
    &.card-link {
        border-radius: 0;
        border-bottom-right-radius: $border-radius;
        border: 0;
        color: $body-color;
        background-color: $gray-100;
        padding: $card-spacer-y $card-spacer-x;
        @include media-breakpoint-up(md) {
            padding: 0;
            height: 100%;
            background-color: $gray-100;
        }
        .card-header {
            border-radius: 0;
            font-size: 1rem;
            color: $body-color;
            padding: $card-spacer-y 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 0 !important;
            border-bottom: 0;
            background-color: transparent;
            a {
                color: $body-color;
            }
            @include media-breakpoint-up(md) {
                display: block;
                min-height: 90px;
                @include background-primary(70deg);
                color: $white;
                font-size: rem(18);
                padding: $card-spacer-y $card-spacer-x;
                a {
                    color: $white;
                }
            }
        }
        .card-body {
            padding:  0.3rem 0 $card-spacer-y 0;
            @include media-breakpoint-up(md) {
                padding: $card-spacer-x;
            }
            hr {
                margin: 0 0 1em;
                border-top-color: rgba(0, 0, 0, 0.2);
            }
            a {
                color: $body-color;
            }
            ul {
                padding-left: 1.2rem;
                list-style-type: disc;
            }
        }
    }

    &.card-link { // card block but clickable
        text-decoration: none;
        background-color: $white;
        padding: 0;
        margin: 0;
        @include media-breakpoint-up(md) {
            background-color: $gray-100;
        }
        .card-header {
            border-bottom: $card-border-width solid $card-border-color;
            &:after {
                @include faw-icon($fa-var-chevron-right,12);
                margin-top: 1px;
            }
            @include media-breakpoint-up(md) {
                border-bottom: 0;
                min-height: 0;
                color: $white !important;
                &:after {
                    content: none;
                }
            }
        }
        @include media-breakpoint-up(md) {
            transform: scale(1);
            transition: $transition-base;
            &:hover {
                transform: scale(1.02);
                box-shadow: $box-shadow-card;
            }
        }
        &.card-link-primary {
            * {
                color: $body-color;
            }
            @include media-breakpoint-up(md) {
                @include background-primary(70deg);
                * {
                    color: $white;
                }
                .card-header {
                    background-color: transparent;
                    min-height: 0;
                    padding: 0;
                }
            }
        }
    }
}
.row-deck {
    .card-block {
        .card-header {
            @include media-breakpoint-up(md) {
                min-height: 100px;
            }
        }
    }
    .col-lg-3 {
        .card-block .card-header {
            @include media-breakpoint-up(md) {
                min-height: 130px;
            }
        }
    }
}