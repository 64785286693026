.accordion {
    border-bottom: 1px solid $border-color;
    margin-bottom: 1.5rem;

    .accordion-title {
        font-weight: 400;
        padding: 1rem 0;
        margin-bottom: 0 !important;
        margin-top: 0 !important;
        border-top: 1px solid $border-color;

        a {
            color: $body-color;
            padding-left: 2rem !important;
        }
    }
    @include media-breakpoint-down(md) {
        .accordion-title a,
        .accordion-content {
            padding-right: 1rem;
            padding-left: 1.625rem;
        }
    }
}