#doormat .doormat-citizen {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      break-inside: avoid-column;
      margin: 0;
      padding: .45rem 0 .45rem 0;
      display: block;
      width: 100%;
      @include media-breakpoint-up(md) {
        width: 95%;
      }
      ul {
        margin-top: .45rem;
        margin-left: 1rem;
        margin-bottom: 1rem;
        font-size: 0.875em;
        li {
          width: 100%;
        }
      }
    }
  }
  > ul {
    @include media-breakpoint-up(md) {
      column-count: 2;
      column-gap: $grid-gutter-width;
    }
    @include media-breakpoint-up(lg) {
      column-count: 3;
    }
  }
}